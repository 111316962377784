/*
 * facade to some of the methods defined in https://docs.appcelerator.com/arrowdb/latest/#!/api/PushNotifications-method-channels_show
 */
let request = obj => {
    //console.log(obj);
    return new Promise((resolve, reject) => {
        let xhr = new XMLHttpRequest();
        xhr.open(obj.method || "GET", obj.url);
        if (obj.headers) {
            Object.keys(obj.headers).forEach(key => {
                xhr.setRequestHeader(key, obj.headers[key]);
            });
        }
        xhr.onload = () => {
            if (xhr.status >= 200 && xhr.status < 300) {
                //console.log(xhr.response);
                resolve(xhr.response);
            } else {
                //console.error(xhr.statusText);
                reject(xhr.statusText);
            }
        };
        xhr.onerror = () => reject(xhr.statusText);
        xhr.send(JSON.stringify(obj.body));
    });
};

export const login = async (login, password, key) => {
    let authObject = await request({
        method: 'POST',
        headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json'
        },
        body: {
            login: login,
            password: password
        },
        json: true,
        url: `api/users/login.json?key=${key}`
    });
    return JSON.parse(authObject);
}

export const getListOfSubscribersToChannels = async (token, channels, key) => {
    let url = `api/push_notification/query.json?key=${key}`;
    for (const channel of channels) {
        url = url + `&channel=${channel}`;
    }
    let subscriptions = await request({
        method: 'POST',
        headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json'
        },
        url: url,
        body: {
            session_id: token,
            per_page: 1000,
        },
        json: true
    });
    return JSON.parse(subscriptions);
}

export const sendPushToIdsForChannels = async (token, ids, channels, payload, key) => {
    let sendResult;
    sendResult = await request({
        method: 'POST',
        url: `api/push_notification/notify.json?key=${key}`,
        header: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json'
        },
        body: {
            session_id: token,
            channel: channels,
            to_ids: ids,
            payload: payload
        }
    });
    return JSON.parse(sendResult);
}

export const sendPushToChannels = async (token, channels, payload, key) => {
    let sendResult;
    sendResult = await request({
        method: 'POST',
        url: `api/push_notification/notify.json?key=${key}`,
        header: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json'
        },
        body: {
            session_id: token,
            channel: channels,
            to_ids: 'everyone',
            payload: payload
        }
    });
    return JSON.parse(sendResult);
}