import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormHelperText } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { withFirebase } from '../Firebase';

const styles = theme => ({});
class ErrorTextBase extends Component {
    render() {
        if (this.props.display === true) {
            return (
                <FormHelperText error={true} >{this.props.text}</FormHelperText>
            )
        } else {
            return (
                <div></div>
            )
        }
    }
}

ErrorTextBase.propTypes = {
    classes: PropTypes.object.isRequired,
};

const ErrorText = withStyles(styles)(withFirebase(ErrorTextBase));
export default ErrorText;