import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import { withFirebase } from '../Firebase';
import ProgressBar from '../ProgressBar';
import ErrorText from '../ErrorText';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Redirect } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import DeleteIcon from '@material-ui/icons/Delete';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';


const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
    },
    card: {
        minWidth: 275,
    },
    list: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    panel: {
        marginTop: theme.spacing.unit * 8,
        display: 'grid'
    },
    paper: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
    },
    submit: {
        marginTop: theme.spacing.unit * 3,
    },
});

class CannedMessageFormBase extends Component {
    constructor(props) {
        super(props);
        this.unsubcribe = null;
        this.state = {
            titleIsInvalid: false,
            InvalidTitleErrorText: '',
            loggedIn: false,
            titleText: null,
            showProgressBar: true,
            snackBarMessage: 'message',
            showSnackBar: false,
            dense: false,
            secondary: false,
            cannedMessages: []
        };
        this.onsubmit = this.onsubmit.bind(this);
        this.messageTitleHandler = this.messageTitleHandler.bind(this);
    }

    componentDidMount() {
        this.unsubscribe = this.props.firebase.doOnAuthStateChanged((user) => {
            if (user) {
                this.setState({
                    loggedIn: true
                });
                this.getFirebaseData();
            } else {
                this.setState({
                    loggedIn: false
                });
            }
        });
    }

    componentWillUnmount() {
        if (typeof this.unsubcribe === 'function') {
            this.unsubcribe();
        }
    }

    getFirebaseData = async () => {
        try {
            let cannedMessageData = await this.props.firebase.doGetUserCannedMessages();
            this.setState({
                cannedMessages: cannedMessageData,
                showProgressBar: false
            });
        } catch (ex) {
            console.error(String(ex));
            this.setState({
                cannedMessages: [],
                showProgressBar: false
            });
        }
    }

    deleteUserMessage = async (id) => {
        try {
            await this.props.firebase.doDeleteUserCannedMessage(id);
            let cannedMessageData = await this.props.firebase.doGetUserCannedMessages();
            this.setState({
                cannedMessages: cannedMessageData
            });
        } catch (ex) {
            console.error(String(ex));
        }
    }


    formIsNotValid = () => {
        let { titleIsInvalid, titleText, cannedMessages } = this.state;
        let textLengthOK = false;
        const hasTitleText = (titleText !== '' && titleText !== null) ? true : false;
        if (titleText && titleText.length && titleText.length >= 5) {
            textLengthOK = true;
        }
        let maxMessagesExceeded = cannedMessages.length >= 5 ? true : false;
        const titleIsValid = titleIsInvalid === true ? false : true;
        if (!maxMessagesExceeded && hasTitleText && titleIsValid && textLengthOK) {
            return false;
        }
        return true;
    }

    messageTitleHandler = event => {
        this.setState({ titleText: event.target.value });
        this.formIsNotValid();
    }

    messageTitleValidator = event => {
        if (event.target.value.length < 5 || event.target.value.length > 200) {
            this.setState({
                titleIsInvalid: true,
                InvalidTitleErrorText: `expected message title length of between 5 and 200 characters but ${event.target.value.length} have been added`,

            });
        } else {
            this.setState({
                titleIsInvalid: false,
                InvalidTitleErrorText: ''
            });
        }
        this.formIsNotValid();
    }

    handleSnackBarClose = async () => {
        //console.log("****", "handleSnackBarClose");
        let cannedMessageData = await this.props.firebase.doGetUserCannedMessages();
        this.setState({
            showSnackBar: false,
            titleText: "",
            cannedMessages: cannedMessageData
        });
    };

    onsubmit = async () => {
        try {
            //console.log("****", "onsubmit");
            this.setState({
                showProgressBar: true,
                showSnackBar: true,
                snackBarMessage: "updating messages"
            });
            await this.props.firebase.doSaveCannedMessage({ text: this.state.titleText });
            this.setState({
                showProgressBar: false
            });
        } catch (ex) {
            this.setState({
                showProgressBar: false,
                showSnackBar: true,
                snackBarMessage: String(ex)
            });
        }

    }
    render() {
        const { classes } = this.props;
        const hasExistingMessages = this.state.cannedMessages.length > 0 ? true : false;
        let user = this.props.firebase.doGetCurrentUser();
        if (user !== null) {
            if (user.emailVerified === true) {
                return (
                    <main>
                        <Paper className={classes.root}>
                            <Typography variant="h6" gutterBottom>Favourites</Typography>
                            <Typography variant="body2" gutterBottom>
                                A maximum of five favourites may be added.
                            </Typography>
                            <div>
                                <Typography variant="subtitle1" gutterBottom>Current Favourites</Typography>
                                {hasExistingMessages ? (
                                    <List className={classes.root}>{this.state.cannedMessages.map((message) =>
                                        <div>
                                            <ListItem key={message.id}>
                                                <ListItemText primary={message.text} />
                                                <ListItemSecondaryAction>
                                                    <IconButton aria-label="Delete">
                                                        <DeleteIcon onClick={() => this.deleteUserMessage(message.id)} />
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                            <li>
                                                <Divider />
                                            </li>
                                        </div>
                                    )}</List>
                                ) : (
                                        <Typography variant="body2" gutterBottom>None</Typography>
                                    )
                                }
                            </div>
                        </Paper>
                        <Paper className={classes.paper}>
                            <form className={classes.form}>
                                <FormControl margin="normal" required fullWidth>
                                    <InputLabel htmlFor="title">Message Text</InputLabel>
                                    <Input error={this.state.titleIsInvalid} id="title" name="title" onBlur={this.messageTitleValidator} onChange={this.messageTitleHandler} value={this.state.titleText} />
                                    <ErrorText display={this.state.titleIsInvalid} text={this.state.InvalidTitleErrorText} />
                                    <ProgressBar display={this.state.showProgressBar} />
                                    <Grid container direction="row" justify="space-between" spacing={8}>
                                        <Button
                                            disabled={this.formIsNotValid() || this.state.showProgressBar}
                                            onClick={this.onsubmit}
                                            color="primary"
                                            className={classes.submit}>
                                            Add
                                        </Button>
                                    </Grid>
                                </FormControl>
                            </form>
                        </Paper>
                        <Paper className={classes.root}>
                            <Typography variant="caption" gutterBottom>
                                Favourites are a convenient method for sending push notifications. You can select and edit them when sending a push message.  Favourites are stored in your user account, not per organisation.
                            </Typography>
                        </Paper>
                        <Snackbar
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            open={this.state.showSnackBar}
                            autoHideDuration={5000}
                            onClose={this.handleSnackBarClose}
                            ContentProps={{
                                'aria-describedby': 'message-id',
                            }}
                            message={<span id="message-id">{this.state.snackBarMessage}</span>}
                            action={[
                                <IconButton
                                    key="close"
                                    aria-label="Close"
                                    color="inherit"
                                    onClick={this.handleSnackBarClose}
                                    className={classes.close}>
                                    <CloseIcon />
                                </IconButton>,
                            ]}
                        />
                    </main >
                )
            } else {
                return (
                    <Redirect to="/signin" />
                )
            }
        } else {
            return (
                <Redirect to="/signin" />
            )
        }
    }
}

CannedMessageFormBase.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withFirebase(CannedMessageFormBase));