import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withFirebase } from '../Firebase';
import { Redirect } from 'react-router-dom'

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
    },
    hiddenStyle: { height: 0 },
});
class App extends Component {
    constructor(props) {
        super(props);
        this.unsubscribe = null;
        this.state = {
            loggedIn: false
        };
    }

    componentDidMount() {
        this.unsubcribe = this.props.firebase.doOnAuthStateChanged((user) => {
            //setting state here just to force the bar to rerender
            if (user) {
                this.setState({
                    loggedIn: true
                });
            } else {
                this.setState({
                    loggedIn: false
                });
            }
        });
    }

    componentWillUnmount() {
        if (typeof this.unsubcribe === 'function') {
            this.unsubcribe();
        }
    }

    render() {
        const user = this.props.firebase.doGetCurrentUser();
        if (user === null || user.emailVerified !== true) {
            return (
                <Redirect to="/signin" />
            );
        } else {
            return (
                <Redirect to="/send" />
            );
        }

    }
}
App.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withFirebase(App));