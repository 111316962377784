export const INITIAL_STATE = {
    username: '',
    password: '',
    active: 'signin',
    leftButtonVal: 'Sign In',
    rightButtonVal: 'Forgot Password',
    value: 0,
    displayName: '',
    email: '',
    emailVerified: false,
    photoURL: '',
    isAnonymous: false,
    uid: '',
    providerData: '',
    loggedIn: false,
    statusMessage: `Status: signed out`,
    disableSend: true,
    openModal: false
}