import React from 'react';
import ReactDOM from 'react-dom';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import theme from './components/theme';
import './index.css';
import SignIn from './components/SignIn';
import MessageForm from './components/MessageForm';
import CannedMessageForm from './components/CannedMessageForm';
import ButtonAppBar from './components/ButtonAppBar';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import * as serviceWorker from './serviceWorker';

import App from './components/App';
import Firebase, { FirebaseContext } from './components/Firebase';

ReactDOM.render(
    <FirebaseContext.Provider value={new Firebase()}>
        <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <Router>
                <div>
                    <ButtonAppBar />
                    <Route path="/" component={App} />
                    <Route path="/signin" component={SignIn} />
                    <Route path="/send" component={MessageForm} />
                    <Route path="/favourites" component={CannedMessageForm} />
                </div>
            </Router>
        </MuiThemeProvider>
    </FirebaseContext.Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
