import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import { withFirebase } from '../Firebase';
import ProgressBar from '../ProgressBar';
import { login, sendPushToChannels } from '../Arrow';
import ErrorText from '../ErrorText';
import { FormLabel, FormControlLabel } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Redirect } from 'react-router-dom';

const styles = theme => ({
    card: {
        minWidth: 275,
    },
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    panel: {
        marginTop: theme.spacing.unit * 8,
        display: 'grid'
    },
    paper: {
        //marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
    },
    submit: {
        marginTop: theme.spacing.unit * 3,
    },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

class MessageFormBase extends Component {
    constructor(props) {
        super(props);
        this.unsubcribe = null;
        this.state = {
            selection: 'all',
            organisation: 'none',
            organisations: { 'none': { channels: [] } },
            titleIsInvalid: false,
            InvalidTitleErrorText: '',
            messageBodyIsInvalid: false,
            InvalidMessageBodyErrorText: '',
            channels: [],
            loggedIn: false,
            disableSelect: true,
            bodyText: null,
            titleText: null,
            showProgressBar: true,
            snackBarMessage: 'message',
            showSnackBar: false,
            useCannedMessage: false,
            cannedMessages: ["abandon ship"]
        };
        this.onsubmit = this.onsubmit.bind(this);
        this.handleSelections = this.handleSelections.bind(this);
        this.handleRBChange = this.handleRBChange.bind(this);
        this.handleOrganisationChange = this.handleOrganisationChange.bind(this);
        this.messageBodyHandler = this.messageBodyHandler.bind(this);
        this.messageTitleHandler = this.messageTitleHandler.bind(this);
        this.handleCannedSelection = this.handleCannedSelection.bind(this);
    }

    componentDidMount() {
        //console.log(`valid: ${this.formIsNotValid()} should hide ${this.state.showProgressBar}`);
        this.unsubscribe = this.props.firebase.doOnAuthStateChanged((user) => {
            //setting state here just to force the bar to rerender
            if (user) {
                this.setState({
                    loggedIn: true
                });
                this.getFirebaseData();
            } else {
                this.setState({
                    loggedIn: false
                });
            }
        });
    }

    componentWillUnmount() {
        //console.log("MessageForm", "componentWillUnmount");
        if (typeof this.unsubcribe === 'function') {
            //console.log("MessageForm.js", "unsubscribed", "doOnAuthStateChanged");
            this.unsubcribe();
        }
    }

    getFirebaseData = async () => {
        try {
            let userOrganisationsAndChannels = await this.props.firebase.doGetUserOrganisationsAndChannels();
            let keys = Object.keys(userOrganisationsAndChannels);
            let organisation = keys[0] || 'none';
            this.setState({
                organisation: organisation,
                organisations: userOrganisationsAndChannels
            });
            let cannedMessageData = await this.props.firebase.doGetAllCannedMessages();
            let cannedMessages = cannedMessageData.map(item => item.text);
            this.setState({
                cannedMessages: cannedMessages,
                showProgressBar: false
            });
        } catch (ex) {
            console.error(String(ex));
            this.setState({
                organisation: 'none',
                organisations: { 'none': { channels: [] } },
                showProgressBar: false
            });
        }
    }

    handleCannedSelection = event => {
        const msg = this.state.cannedMessages[event.target.value];
        this.setState({
            bodyText: msg,
            useCannedMessage: event.target.value
        });
    }

    formIsNotValid = () => {
        let { selection, messageBodyIsInvalid, titleIsInvalid, bodyText, titleText } = this.state;
        const hasSelection = selection !== null ? true : false;
        const hasBodyText = bodyText !== null ? true : false;
        const hasTitleText = titleText !== '' ? true : false;
        const bodyIsValid = messageBodyIsInvalid === true ? false : true;
        const titleIsValid = titleIsInvalid === true ? false : true;

        if (hasSelection && hasBodyText && hasTitleText && bodyIsValid && titleIsValid) {
            return false;
        }
        return true;
    }

    messageBodyHandler = event => {
        this.setState({
            bodyText: event.target.value,
            useCannedMessage: false
        });
        this.formIsNotValid();
    }

    messageTitleHandler = event => {
        this.setState({ titleText: event.target.value });
        this.formIsNotValid();
    }

    messageTitleValidator = event => {
        if (event.target.value.length < 4 || event.target.value.length > 50) {
            this.setState({
                titleIsInvalid: true,
                InvalidTitleErrorText: `expected message title length of between 4 and 50 characters but ${event.target.value.length} have been added`,

            });
        } else {
            this.setState({
                titleIsInvalid: false,
                InvalidTitleErrorText: ''
            });
        }
        this.formIsNotValid();
    }

    messageBodyValidator = event => {
        if (event.target.value.length < 5 || event.target.value.length > 1500) {
            this.setState({
                messageBodyIsInvalid: true,
                InvalidMessageBodyErrorText: `expected message body length of between 5 and 1500 characters but ${event.target.value.length} have been added`,

            });
        } else {
            this.setState({
                messageBodyIsInvalid: false,
                InvalidMessageBodyErrorText: ``,
            });
        }
        this.formIsNotValid();
    }

    handleSelections = event => {
        this.setState({
            channels: event.target.value
        });
    };
    handleRBChange = event => {
        if (event.target.value === "all" || this.state.organisations[this.state.organisation].channels.length === 0) {
            this.setState({
                channels: [`${this.state.organisation}_all`],
                selection: "all",
                disableSelect: true
            });
        } else {
            this.setState({
                disableSelect: false,
                selection: "selected"
            });
        }
    }
    handleOrganisationChange = event => {
        //update state
        //console.log(this.state.organisations[event.target.value]);
        if (this.state.organisations[event.target.value].channels.length === 0) {
            this.setState({
                channels: [`${event.target.value}_all`],
                selection: "all",
                disableSelect: true,
                organisation: event.target.value
            });
        } else {
            this.setState({
                channels: [],
                organisation: event.target.value
            });
        }

    }

    handleSnackBarClose = (event, reason) => {
        this.setState(
            {
                showSnackBar: false,
                bodyText: "",
                titleText: "",
                useCannedMessage: false
            }
        );
    };

    onsubmit = async (e) => {
        this.setState({
            showProgressBar: true
        });
        try {
            let user = await this.props.firebase.doGetCurrentUser();
            let channels = [];
            let disclaimer = "This is a back up system message. After you dismiss it, it will not longer be visible.";
            let bodyText = `${this.state.bodyText}\n${disclaimer}`;
            if (this.state.channels.length === 0) {
                channels = [`${this.state.organisation}_all`];
            } else {
                channels = this.state.channels;
            }

            let payload = {
                "alert": bodyText,
                "intro": "",
                "rich": "",
                "title": this.state.titleText,
                "heading": this.state.titleText,
                "defaults": -1,
                "vibrate": true,
                "sound": "default",
                "icon": "appicon",
                "largeIcon": "Ti.App.Android.R.drawable.square",
                "dur": 1500,
                "style": "success",
                "bg": "#222222",
                "fnt": "#ffffff",
                "pers": 1,
                "toast": 1
            };

            let { organisations, organisation } = this.state;
            let container = organisations[organisation].container;
            let data = await login(container.user, container.password, container.key);
            let token = data.meta.session_id;

            let pushResult = await sendPushToChannels(token, channels, payload, container.key);
            let msg;
            if (pushResult.meta.status === "ok") {
                msg = "push notification sent ok";
            } else {
                msg = `push failed with status ${pushResult.meta.status}`;
            }
            console.log(JSON.stringify(pushResult));
            let channelsSet = new Set(channels); //stop dups
            await this.props.firebase.doSaveMessage({
                user: user.email,
                organisation: organisation,
                payload: { title: payload.title, alert: this.state.bodyText },
                channels: [...channelsSet],
                meta: { code: pushResult.meta.code, status: pushResult.meta.status }
            }, "messages");

            this.setState({
                showSnackBar: true,
                snackBarMessage: msg,
                showProgressBar: false
            });

            console.log("****", "onsubmit");
        } catch (ex) {
            this.setState({
                showSnackBar: true,
                snackBarMessage: String(ex),
                showProgressBar: false
            });
        }

    }
    render() {
        const { classes } = this.props;
        let user = this.props.firebase.doGetCurrentUser();
        if (user !== null) {
            if (user.emailVerified === true) {
                return (
                    <main>
                        <Paper className={classes.paper}>
                            <form className={classes.form}>
                                <FormControl margin="normal" required fullWidth>
                                    <FormLabel component="legend">Message Title</FormLabel>
                                    <Input error={this.state.titleIsInvalid} id="title" name="title" onBlur={this.messageTitleValidator} onChange={this.messageTitleHandler} value={this.state.titleText} />
                                    <ErrorText display={this.state.titleIsInvalid} text={this.state.InvalidTitleErrorText} />
                                </FormControl>
                                <FormControl margin="normal" fullWidth>
                                    <FormLabel component="legend">Favourite</FormLabel>
                                    <Select
                                        value={this.state.useCannedMessage}
                                        onChange={this.handleCannedSelection}
                                        input={<Input id="select-canned" />}
                                        MenuProps={MenuProps}>
                                        {
                                            this.state.cannedMessages.map((msg, idx) => (
                                                <MenuItem key={idx} value={idx}>{msg}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                                <FormControl margin="normal" required fullWidth>
                                    <FormLabel component="legend">Message Body</FormLabel>
                                    <Input multiline error={this.state.messageBodyIsInvalid} id="messagebody" name="messagebody" onBlur={this.messageBodyValidator} onChange={this.messageBodyHandler} value={this.state.bodyText} />
                                    <ErrorText display={this.state.messageBodyIsInvalid} text={this.state.InvalidMessageBodyErrorText} />
                                </FormControl>
                                <FormControl component="fieldset" required fullWidth>
                                    <FormLabel component="legend">Organisation</FormLabel>
                                    <RadioGroup
                                        aria-label="Organisation"
                                        value={this.state.organisation}
                                        onChange={this.handleOrganisationChange}>
                                        {
                                            Object.keys(this.state.organisations).map(org => (
                                                <FormControlLabel key={org} value={org} control={<Radio />} label={this.state.organisations[org].title} />
                                            ))
                                        }
                                    </RadioGroup>
                                </FormControl>
                                <FormControl component="fieldset" required fullWidth>
                                    <FormLabel component="legend">Channel</FormLabel>
                                    <RadioGroup
                                        aria-label="Channel"
                                        value={this.state.selection}
                                        onChange={this.handleRBChange}>
                                        <FormControlLabel value="all" control={<Radio />} label="All" />
                                        <FormControlLabel value="selected" control={<Radio />} label="Selected" />
                                    </RadioGroup>
                                </FormControl>
                                <FormControl disabled={this.state.disableSelect} margin="normal" fullWidth>
                                    <InputLabel htmlFor="select-multiple">Selected Channels</InputLabel>
                                    <Select
                                        multiple
                                        value={this.state.channels}
                                        onChange={this.handleSelections}
                                        input={<Input id="select-multiple" />}
                                        MenuProps={MenuProps}>
                                        {
                                            this.state.organisations[this.state.organisation].channels.map(channel => (
                                                <MenuItem key={channel.channel} value={channel.channel}>{channel.title}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                    <ProgressBar display={this.state.showProgressBar} />
                                    <Grid container direction="row" justify="space-between" spacing={8}>
                                        <Button
                                            disabled={this.formIsNotValid() || this.state.showProgressBar}
                                            onClick={this.onsubmit}
                                            color="primary"
                                            className={classes.submit}>
                                            send
                                        </Button>
                                    </Grid>
                                </FormControl>
                            </form>
                        </Paper>
                        <Snackbar
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            open={this.state.showSnackBar}
                            autoHideDuration={5000}
                            onClose={this.handleSnackBarClose}
                            ContentProps={{
                                'aria-describedby': 'message-id',
                            }}
                            message={<span id="message-id">{this.state.snackBarMessage}</span>}
                            action={[
                                <IconButton
                                    key="close"
                                    aria-label="Close"
                                    color="inherit"
                                    onClick={this.handleSnackBarClose}
                                    className={classes.close}>
                                    <CloseIcon />
                                </IconButton>,
                            ]}
                        />
                    </main>
                )
            } else {
                return (
                    <Redirect to="/signin" />
                )
            }
        } else {
            return (
                <Redirect to="/signin" />
            )
        }
    }
}


MessageFormBase.propTypes = {
    classes: PropTypes.object.isRequired,
};

const MessageForm = withStyles(styles)(withFirebase(MessageFormBase));
export default MessageForm;