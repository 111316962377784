import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
const config = {
    apiKey: "AIzaSyARbsBDuQkozt_5vTh3eMxsQPd7JVMPyNw",
    authDomain: "piota-slack-bot.firebaseapp.com",
    databaseURL: "https://piota-slack-bot.firebaseio.com",
    projectId: "piota-slack-bot",
    storageBucket: "piota-slack-bot.appspot.com",
    messagingSenderId: "176312797119"
};
const getSnapshotDataAndId = (snapshot) => {
    let data = snapshot.data();
    data.id = snapshot.id;
    return data;
}
class Firebase {
    constructor() {
        app.initializeApp(config);
        this.auth = app.auth();
        this.db = app.firestore();
    }

    doOnAuthStateChanged = (callback) => this.auth.onAuthStateChanged(callback);

    doCreateUserWithEmailAndPassword = (email, password) =>
        this.auth.createUserWithEmailAndPassword(email, password);

    doSignInWithEmailAndPassword = (email, password) =>
        this.auth.signInWithEmailAndPassword(email, password);

    doSignOut = () => this.auth.signOut();

    doPasswordReset = email =>
        this.auth.sendPasswordResetEmail(email);

    doPasswordUpdate = password =>
        this.auth.currentUser.updatePassword(password);

    doSendEmailVerification = () =>
        this.auth.currentUser.sendEmailVerification();

    doSendPasswordResetEmail = (email) =>
        this.auth.sendPasswordResetEmail(email);

    doGetCurrentUser = () => {
        return this.auth.currentUser;
    }
    doDisableNetwork = () => this.db.disableNetwork();

    doGetCollection = (collection) =>
        this.db.collection(collection);

    doGetDataAllFromCollection = async (collection) => {
        const ref = this.db.collection(collection);
        const query = await ref.get();
        return query.docs.map(getSnapshotDataAndId);
    }

    doGetContainerData = async (container) => {
        let data = {};
        try {
            let containerCollection = this.db.collection("containers");
            let query = containerCollection.where("name", "==", container);
            let snapshot = await query.get();
            if (snapshot.size) {
                await snapshot.forEach(doc => { //there will only ever be one, but not a biggie using a forEach here
                    data = doc.data();
                });
            }
            return data;
        } catch (ex) {
            console.error(String(ex));
            return data;
        }
    }
    doGetUserOrganisationsAndChannels = async () => {
        try {
            const currentUser = this.auth.currentUser;
            let data = {};
            let userIds = [];
            let userOrganisationNames = [];
            const usersCollection = this.db.collection("users");
            let organisationsCollection = this.db.collection('organisations');
            let query = usersCollection.where('email', '==', currentUser.email);
            let usersnapshot = await query.get();
            if (usersnapshot.size) {
                await usersnapshot.forEach(doc => {
                    userIds.push(doc.id);
                });
                for (const userId of userIds) {
                    let userorganisationsCollection = usersCollection.doc(userId).collection("organisations");
                    let userOrganisationsSnapshot = await userorganisationsCollection.get();
                    if (userOrganisationsSnapshot.size) {
                        await userOrganisationsSnapshot.forEach(doc => {
                            let data = doc.data();
                            userOrganisationNames.push(data.name);
                        });
                    }
                }
                for (const organisationName of userOrganisationNames) {
                    let organisationIds = [];
                    let usersChannels = [];
                    let containerName;
                    let organisationTitle;
                    let containerData = {};
                    let oQuery = organisationsCollection.where('organisation', '==', organisationName);
                    let organisationsSnapshot = await oQuery.get();
                    if (organisationsSnapshot.size) {
                        await organisationsSnapshot.forEach(oDoc => {
                            let data = oDoc.data();
                            containerName = data.container;
                            organisationTitle = data.title;
                            organisationIds.push(oDoc.id);
                        });
                    }
                    for (const organisationId of organisationIds) {
                        let channels = organisationsCollection.doc(organisationId).collection("channels");
                        let channelsSnapshot = await channels.get();
                        if (channelsSnapshot.size) {
                            await channelsSnapshot.forEach(cDoc => {
                                let channelData = cDoc.data();
                                usersChannels.push(channelData);
                            });
                        }
                    }
                    if (containerName) {
                        containerData = await this.doGetContainerData(containerName);
                    }
                    data[organisationName] = { container: containerData, channels: usersChannels, title: organisationTitle, id: organisationIds[0] };
                }
            }
            return data;
        } catch (ex) {
            console.error(String(ex));
        }
    }

    doGetUserCannedMessages = async () => {
        try {
            let userMessages = [];
            const currentUser = this.auth.currentUser;
            const usersCollection = this.db.collection("users");
            let userQuery = usersCollection.where('email', '==', currentUser.email);
            let usersnapshot = await userQuery.get();
            let userIds = [];
            //there should only be one id, but this gets around the issue that firebase snapshots are not iterable
            if (usersnapshot.size) {
                await usersnapshot.forEach(doc => {
                    userIds.push(doc.id);
                });
            }
            for (const id of userIds) {
                let ref = usersCollection.doc(id).collection("cannedMessages");
                const query = await ref.get();
                userMessages = query.docs.map(getSnapshotDataAndId);
            }
            return userMessages;
        } catch (ex) {
            console.error(String(ex));
        }
    }

    doDeleteUserCannedMessage = async (docid) => {
        try {
            console.log("doDeleteUserCannedMessage", docid);
            const currentUser = this.auth.currentUser;
            const usersCollection = this.db.collection("users");
            let userQuery = usersCollection.where('email', '==', currentUser.email);
            let usersnapshot = await userQuery.get();
            let userIds = [];
            //there should only be one id, but this gets around the issue that firebase snapshots are not iterable
            if (usersnapshot.size) {
                await usersnapshot.forEach(doc => {
                    userIds.push(doc.id);
                });
            }
            for (const id of userIds) {
                let ref = usersCollection.doc(id).collection("cannedMessages");
                await ref.doc(docid).delete();
                return true;
            }
            return true;
        } catch (ex) {
            console.error(String(ex));
            return false;
        }
    }
    doGetAllCannedMessages = async () => {
        try {
            let userMessages = await this.doGetUserCannedMessages() || [];
            let messages = await this.doGetDataAllFromCollection("cannedMessages") || [];
            return [...userMessages, ...messages];
        } catch (ex) {
            console.error(String(ex));
        }
    }

    doSaveCannedMessage = async (msg) => {
        try {
            const currentUser = this.auth.currentUser;
            const usersCollection = this.db.collection("users");
            let query = usersCollection.where('email', '==', currentUser.email);
            let usersnapshot = await query.get();
            let userIds = [];
            //there should only be one id, but this gets around the issue that firebase snapshots are not iterable
            if (usersnapshot.size) {
                await usersnapshot.forEach(doc => {
                    userIds.push(doc.id);
                });
            }
            for (const id of userIds) {
                let userMessages = usersCollection.doc(id).collection("cannedMessages");
                let doc = userMessages.doc();
                doc.set(msg);
            }
        } catch (ex) {
            console.error(String(ex));
        }
    }

    doSaveMessage = async (msg, collection) => {
        try {
            let ref = this.db.collection(collection).doc();
            msg.timestamp = Date.now();
            ref.set(msg);
        } catch (ex) {
            console.error(String(ex));
        }
    }
}

export default Firebase;