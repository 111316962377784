import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import { withFirebase } from '../Firebase';
import MessageForm from '../MessageForm';
import { INITIAL_STATE } from '../../constants/state';

const styles = theme => ({
    card: {
        minWidth: 275,
    },
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    panel: {
        marginTop: theme.spacing.unit * 8,
        display: 'grid'
    },
    paper: {
        marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
    },
    submit: {
        marginTop: theme.spacing.unit * 3,
    },
});

class SignInBase extends Component {

    constructor(props) {
        super(props);
        this.unsubcribe = null;
        this.state = { ...INITIAL_STATE };
        this.handleTabClick = this.handleTabClick.bind(this);
        this.lhButtonHandler = this.lhButtonHandler.bind(this);
        this.rhButtonHandler = this.rhButtonHandler.bind(this);
        this.fieldOneHandler = this.fieldOneHandler.bind(this);
        this.fieldTwoHandler = this.fieldTwoHandler.bind(this);
        this.showModal = this.showModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount() {
        this.unsubscribe = this.props.firebase.doOnAuthStateChanged((user) => {
            try {
                if (user) {
                    this.setState({
                        displayName: user.displayName,
                        email: user.email,
                        emailVerified: user.emailVerified,
                        photoURL: user.photoURL,
                        isAnonymous: user.isAnonymous,
                        uid: user.uid,
                        providerData: user.providerData,
                        loggedIn: true,
                        leftButtonVal: 'Sign Out',
                        statusMessage: 'Status: OK'
                    });
                    if (user.emailVerified === true) {
                        this.setState({
                            disableSend: false
                        });
                    } else {
                        this.setState({
                            disableSend: true,
                            statusMessage: 'Status: please verify email from Sign Up Tab'
                        });
                    }
                } else {
                    this.setState({
                        displayName: null,
                        email: null,
                        emailVerified: false,
                        photoURL: null,
                        isAnonymous: null,
                        uid: null,
                        providerData: null,
                        loggedIn: false,
                        leftButtonVal: 'Sign In',
                        statusMessage: `Status: logged out`,
                        disableSend: true
                    });
                }
            } catch (ex) {
                this.setState({
                    statusMessage: `Status: onAuthStateChanged error ${String(ex)}`
                });
            }
        });
    }

    componentWillUnmount() {
        //console.log("SignIn", "componentWillUnmount");
        if (typeof this.unsubcribe === 'function') {
            console.log("SignIn.js","unsubscribed", "doOnAuthStateChanged");
            this.unsubcribe();
        }
    }

    showModal() {
        this.setState({ openModal: true });
    }
    closeModal() {
        this.setState({ openModal: false });
    }
    fieldOneHandler(e) {
        this.setState({ username: e.target.value });
    }
    fieldTwoHandler(e) {
        this.setState({ password: e.target.value });
    }
    lhButtonHandler() {
        const { username, password } = this.state;
        if (this.state.loggedIn === false) {
            let methodName = 'doSignInWithEmailAndPassword';
            if (this.state.value === 1) { //on the sign up tab
                methodName = 'doCreateUserWithEmailAndPassword';
            }
            this.props.firebase[methodName](username, password)
                .then(user => {
                    console.log(this.state.username, "logged in");
                })
                .catch(error => {
                    console.error(error);
                    this.setState({
                        displayName: null,
                        email: null,
                        emailVerified: false,
                        photoURL: null,
                        isAnonymous: null,
                        uid: null,
                        providerData: null,
                        loggedIn: false,
                        leftButtonVal: 'Sign In',
                        statusMessage: `Status: ${error.message}`
                    });
                });
        } else {
            this.props.firebase.doSignOut()
                .then(result => {
                    console.log("logged out", result);
                })
                .catch(error => {
                    console.error(error);
                    this.setState({
                        displayName: null,
                        email: null,
                        emailVerified: false,
                        photoURL: null,
                        isAnonymous: null,
                        uid: null,
                        providerData: null,
                        loggedIn: false,
                        leftButtonVal: 'Sign In',
                        statusMessage: `Status: signed out`
                    });
                });
        }
    }
    rhButtonHandler() {
        const { username } = this.state;
        if (this.state.value === 0) { //forgot password option
            this.props.firebase.doSendPasswordResetEmail(username)
                .then(() => {
                    this.setState({
                        statusMessage: `Status: reset email sent`
                    });
                })
                .catch((error) => {
                    this.setState({
                        statusMessage: `Status: ${String(error)}`
                    });
                });
        } else {
            if (this.state.loggedIn === true) {
                this.props.firebase.doSendEmailVerification()
                    .then(() => {
                        this.setState({
                            statusMessage: `Status: verification email sent`
                        });
                    })
                    .catch((error) => {
                        this.setState({
                            statusMessage: `Status: ${String(error)}`
                        });
                    });
            } else {
                this.setState({
                    statusMessage: `Status: please login to request email verification`
                });
            }
        }
    }
    handleTabClick(e, value) {
        this.setState({ value });
        if (value === 1) {
            this.setState({
                leftButtonVal: 'Sign Up',
                rightButtonVal: 'Verify Email'
            });
        } else {
            this.setState({
                leftButtonVal: this.state.loggedIn === true ? 'Sign Out' : 'Sign In',
                rightButtonVal: 'Forgot Password'
            });
        }
    }
    render() {
        const { classes } = this.props;

        return (
            <main className={classes.main}>
                <CssBaseline />
                <Paper className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Tabs
                        value={this.state.value}
                        onChange={this.handleTabClick}
                        indicatorColor="primary"
                        textColor="primary"
                        centered>
                        <Tab label="Sign In" />
                        <Tab label="Sign Up" />
                    </Tabs>
                    <form className={classes.form}>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="email">Email Address</InputLabel>
                            <Input id="email" name="email" value={this.state.username} autoComplete="email" onChange={this.fieldOneHandler} autoFocus />
                        </FormControl>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="password">Password</InputLabel>
                            <Input name="password" type="password" id="password" value={this.state.password} onChange={this.fieldTwoHandler} autoComplete="current-password" />
                        </FormControl>
                        <Grid container direction="row" justify="space-between" spacing={8}>
                            <Button
                                onClick={this.lhButtonHandler}
                                color="primary"
                                className={classes.submit}
                            >
                                {this.state.leftButtonVal}
                            </Button>
                            <Button
                                onClick={this.rhButtonHandler}
                                color="secondary"
                                className={classes.submit}
                            >
                                {this.state.rightButtonVal}
                            </Button>
                        </Grid>
                    </form>
                    <Paper className={classes.panel}>
                        <Card className={classes.card}>
                            <CardContent>
                                <Typography align="center" component="p">
                                    {this.state.statusMessage}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Paper>
                </Paper>
                <Modal
                    open={this.state.openModal}
                    onClose={this.closeModal}
                >
                    <MessageForm />
                </Modal>
            </main>
        );
    }
}
const SignIn = withStyles(styles)(withFirebase(SignInBase));
export default SignIn;