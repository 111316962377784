import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withFirebase } from '../Firebase';
import Button from '@material-ui/core/Button';
import { Link as RouterLink } from 'react-router-dom'
import Link from '@material-ui/core/Link';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';


const drawerWidth = 240;

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 8px',
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
});

class ButtonAppBarBase extends Component {
    constructor(props) {
        super(props);
        this.unsubscribe = null;
        this.state = {
            loggedIn: false,
            open: false
        };
        this.logoutButtonHandler = this.logoutButtonHandler.bind(this);
    }

    handleDrawerOpen = () => {
        this.setState({ open: true });
    };

    handleDrawerClose = () => {
        this.setState({ open: false });
    };

    componentDidMount() {
        this.unsubscribe = this.props.firebase.doOnAuthStateChanged((user) => {
            //setting state here just to force the bar to rerender
            if (user) {
                this.setState({
                    loggedIn: true
                });
            } else {
                this.setState({
                    loggedIn: false
                });
            }
        });
    }

    componentWillUnmount() {
        console.log("ButtonAppBar", "componentWillUnmount");
        if (typeof this.unsubcribe === 'function') {
            console.log("ButtonAppBar.js", "unsubscribed", "doOnAuthStateChanged");
            this.unsubcribe();
        }
    }

    logoutButtonHandler() {
        this.props.firebase.doSignOut();
        this.setState({
            loggedIn: false
        });
    }

    render() {
        const { classes, theme } = this.props;
        const { open } = this.state;
        if (this.props.firebase.doGetCurrentUser() !== null) {
            //console.log("signed in");
            return (
                <div className={classes.root}>
                    <AppBar position="static">
                        <Toolbar>
                            <IconButton className={classes.menuButton} color="inherit" aria-label="Menu" onClick={this.handleDrawerOpen}>
                                <MenuIcon />
                            </IconButton>
                            <Typography variant="h6" color="inherit" className={classes.grow}>Piota Actions</Typography>
                            <Button color="inherit" onClick={this.logoutButtonHandler}>Logout</Button>
                        </Toolbar>
                    </AppBar>
                    <Drawer
                        className={classes.drawer}
                        variant="persistent"
                        anchor="left"
                        open={open}
                        classes={{
                            paper: classes.drawerPaper,
                        }}>
                        <div className={classes.drawerHeader}>
                            <IconButton onClick={this.handleDrawerClose}>
                                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                            </IconButton>
                        </div>
                        <Divider />
                        <List>
                            <ListItem button key="Send Push" onClick={this.handleDrawerClose}>
                                <Link component={RouterLink} to="/signin">
                                    Home
                            </Link>
                            </ListItem>
                            <ListItem button key="Send Push" onClick={this.handleDrawerClose}>
                                <Link component={RouterLink} to="/send">
                                    Send A Push Notification
                            </Link>
                            </ListItem>
                            <ListItem button key="Favourites" onClick={this.handleDrawerClose}>
                                <Link component={RouterLink} to="/favourites">
                                    Favourites
                            </Link>
                            </ListItem>
                        </List>
                        <Divider />
                    </Drawer>
                </div>
            );
        } else {
            //console.log("not signed in");
            return (
                <div className={classes.root}>
                    <AppBar position="static">
                        <Toolbar>
                            <Typography variant="h6" color="inherit" className={classes.grow}>Piota Actions</Typography>
                            <Link component={RouterLink} to="/signin">
                                Login
                            </Link>
                        </Toolbar>
                    </AppBar>
                </div>
            );
        }
    }

}

ButtonAppBarBase.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

const ButtonAppBar = withStyles(styles, { withTheme: true })(withFirebase(ButtonAppBarBase));

export default ButtonAppBar;