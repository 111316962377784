import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const styles = {
    root: {
        flexGrow: 1,
    },
};

class ProgressBarBase extends React.Component {
    render() {
        const { classes, display } = this.props;
        if (display === true) {
            return (
                <div className={classes.root}>
                    <LinearProgress />
                </div>
            );
        } else {
            return (
                <div className={classes.root} />
            );
        }
    }
}

ProgressBarBase.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProgressBarBase);